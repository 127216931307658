import { Paper, Stack } from '@mui/material'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useMe } from '../../hooks/useMe'
import useMessages from '../../hooks/useMessages'
import { useMessagesSubscription } from '../../hooks/useMessagesSubscription'
import { Input } from './Input'
import { MessageGroup } from './MessageGroup'
import { InlineInvitees } from '../../components/InlineInvitees'
import { useInvitees } from '../../hooks/useInvitees'

export const Chat = ({ wannaId }) => {
	const me = useMe()
	const chatEndRef = useRef()
	const [hasInitialScrolled, setHasInitialScrolled] = useState(false)

	const messages = useMessages(wannaId)
	useMessagesSubscription([wannaId])
	const ascendingMessages = useMemo(() => messages
		?.concat()
		?.reverse() // We get them from the server in descending order
		?.sort((a, b) => a.id - b.id) ?? [] // Prolly good, but just in case
    , [messages?.map((m) => m.id), wannaId])
	const groupedMessages = groupMessages(ascendingMessages)

	const invitations = useInvitees(wannaId)
	const myInvitation = invitations?.find((inv) => inv.user.id === me?.id)

	function scrollToEnd(smooth) {
		chatEndRef.current?.scrollIntoView({ behavior: smooth ? 'smooth' : 'auto' })
	}

	// Scroll to bottom on first load
	useEffect(() => {
		if (!messages) return
		scrollToEnd(hasInitialScrolled)
		setHasInitialScrolled(true)
	}, [
		hasInitialScrolled,
		JSON.stringify(messages?.map((message) => message.id)),
		chatEndRef.current,
		setHasInitialScrolled,
	])

	return (
		<Stack
			minHeight={0}
			height='100%'>
			<Stack
				p={1}
				spacing={1}
				overflow='auto'
				flex={1}>
				<Stack gap={0.3}>
					{groupedMessages.map((group, index) => (
						<MessageGroup
							key={index}
							wannaId={wannaId}
							group={group}
							allMessages={ascendingMessages}
						/>
					))}
				</Stack>
				<div
					ref={chatEndRef}
					style={{ marginTop: 0 }}
				/>
			</Stack>
			<Paper
				elevation={4}
				square>
				<Stack gap={1} p={1}>
					<InlineInvitees
						wannaId={wannaId}
						invitations={invitations}
					/>
					<Input
						wannaId={wannaId}
						myInvitation={myInvitation}
					/>
				</Stack>
			</Paper>
		</Stack>
	)
}

function shouldSeparate(a, b) {
	return a?.sender?.id !== b?.sender?.id || Math.abs(a.sent - b.sent) > 1 * 60 * 1000
}

function groupMessages(messages) {
	const groups = []
	let group = []

	for (let i = 0; i < messages.length; i++) {
		if (i === 0 || !shouldSeparate(messages[i - 1], messages[i])) {
			group.push(messages[i])
		} else {
			groups.push(group)
			group = [messages[i]]
		}
	}

	if (group.length > 0) {
		groups.push(group)
	}

	return groups
}

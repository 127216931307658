import { InMemoryCache } from '@apollo/client'

export const createCache = () => {
	const cache = new InMemoryCache({
		typePolicies: {
			Query: {
				fields: {
					me: {
						// TODO is this equivalent to `true`?
						merge: (existing, incoming, { mergeObjects }) => {
							return mergeObjects(existing, incoming)
						},
					},
					// ? Is this the right way to do this? So that a specific query can read from
					// the object that a list query already cached.
					// The wanna root query seems to work without it, so idk.
					user: {
						read: (existing, { args: { id } }) => {
							if (existing) return existing
							const cached = cache.identify({ __typename: 'User', id })
							return cached && { __ref: cached }
						},
					},
					wanna: {
						merge: true,
					},
					connections: {
						merge: false,
					},
				},
			},
			Wanna: {
				fields: {
				},
			},
			User: {
				fields: {
					wannas: {
						merge: false,
					},
				},
			},
		},
	})

	return cache
}

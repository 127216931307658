import { useMutation } from '@apollo/client'
import I_WANNA from '../gql/iWanna.gql'
import { useMe } from './useMe'

export const useIWanna = () => {
	const me = useMe()
	const [iWanna] = useMutation(I_WANNA, {
		update: (cache, { data: { iWanna } }) => {
			const newWannaId = cache.identify(iWanna)
			cache.modify({
				id: cache.identify(me),
				fields: {
					currentWanna: () => iWanna,
					invitedWannas: (existingWannas = []) => {
						return [{ __ref: newWannaId }, ...existingWannas]
					},
				},
			})
		},
	})

	return (activity, shareable, startTime, inviteUserIds) => {
		iWanna({
			variables: {
				activity,
				shareable,
				startTime,
				inviteUserIds,
			},
		})
	}
}

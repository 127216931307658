import {
	Paper
} from '@mui/material'
import { useCallback, useRef } from 'react'
import Typed from 'typed.js'
import { shuffle } from '../../utils/utils.js'

export const ActivityInput = ({ activity, setActivity, disabled }) => {
	const typedRef = useRef()
	const inputRef = useCallback((node) => {
		typedRef.current?.destroy()
		if (!node) return
		typedRef.current = new Typed(node, {
			strings: activites,
			typeSpeed: 70,
			backSpeed: 50,
			backDelay: 500,
			attr: 'placeholder',
			loop: true,
		})
	}, [])

	return (
		<Paper
			sx={{
				mt: 2,
				background: 'white',
				borderRadius: '8px',
				outline: 'none',
				width: '100%',
			}}
			elevation={2}>
			<input
				ref={inputRef}
				style={{
					width: '100%',
					background: 'white',
					borderRadius: '8px',
					fontSize: '20px',
					padding: '8px',
					textAlign: 'center',
					border: 'none',
					fontFamily: 'inherit',
					outline: 'none',
				}}
				placeholder=''
				value={activity}
				disabled={disabled}
				onChange={(e) => setActivity(e.target.value)}
			/>
		</Paper>
	)
}

const activites = shuffle(
	[
		'get dinner',
		'watch a movie',
		'hit the gym',
		'walk and talk',
		'play board games',
		'go for a ride',
		'read at the park',
	],
	Date.now()
)

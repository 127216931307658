import { useMutation, useQuery } from '@apollo/client'
import IM_DOWN from '../gql/imDown.gql'
import IM_DOWN_DATA from '../gql/imDownData.gql'
import IM_NOT_DOWN from '../gql/imNotDown.gql'
import { useMe } from './useMe'

export const useImDown = (wannaId) => {
	const me = useMe()
	const imDownData = useQuery(IM_DOWN_DATA, {
		variables: {
			wannaId,
		},
	})
	const invitations = imDownData.data?.wanna.invitations
	const myInvitation = invitations?.find((inv) => inv.user.id === me?.id)
	const amIDown = myInvitation?.rsvp === 'YES'

	const [imDown] = useMutation(IM_DOWN, {
		optimisticResponse: {
			__typename: 'Mutation',
			imDown: {
				id: myInvitation?.id,
				__typename: 'Invitation',
				rsvp: 'YES',
			},
		},
	})

	const [imNotDown] = useMutation(IM_NOT_DOWN, {
		variables: {
			wannaId,
		},
		optimisticResponse: {
			__typename: 'Mutation',
			imNotDown: {
				id: myInvitation?.id,
				__typename: 'Invitation',
				rsvp: null,
			},
		},
	})

	return {
		imDown: (shareWithUserIds) =>
			imDown({
				variables: {
					wannaId,
					shareWithUserIds,
				},
			}),
		imNotDown,
		amIDown,
		shareable: imDownData.data?.wanna.shareable,
	}
}

import { CircularProgress, Stack } from '@mui/material'
import { Suspense, lazy } from 'react'

const UserGraph = lazy(() =>
	import('./UserGraph')
		// Must be the default export for lazy
		.then((module) => ({ default: module.UserGraph })),
)

export const LazyUserGraph = (props) => {
	const height = props.height ?? 200
	return (
		<Suspense
			fallback={
				<Stack
					height={height}
					justifyContent='center'
					alignItems='center'>
					{height > 0 && <CircularProgress sx={{ height: 64, width: 64 }} />}
				</Stack>
			}>
			<UserGraph
				{...props}
				height={height}
			/>
		</Suspense>
	)
}

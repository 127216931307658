import dayjs from 'dayjs'

let startingMessageId = 100

export const circles = [
	{
		__typename: 'Circle',
		id: '456',
		name: 'cool cats club',
		private: false,
	},
]

export const users = {
	jeff: {
		__typename: 'User',
		id: 'jeff',
		name: 'Jeff',
		pic: '/demo/jeff.webp',
		status: 'FLEXIBLE',
		wannas: [],
		connections: ['james', 'nick', 'sparkyy'].map((id) => ({
			__typename: 'User',
			id,
		})),
		connectedBy: [
			{
				__typename: 'Friendship',
			},
			{
				...circles[0],
				members: undefined,
			},
		],
	},
	james: {
		__typename: 'User',
		id: 'james',
		name: 'James',
		pic: '/demo/james.webp',
		status: null,
		wannas: [],
		connections: [],
		connectedBy: [
			{
				__typename: 'Friendship',
			},
		],
	},
	nick: {
		__typename: 'User',
		id: 'nick',
		name: 'Nick',
		pic: '/demo/nick.webp',
		status: null,
		wannas: [],
		connections: ['sparkyy', 'james'].map((id) => ({ __typename: 'User', id })),
		connectedBy: [
			{
				__typename: 'Friendship',
			},
		],
	},
	sparky: {
		__typename: 'User',
		id: 'sparkyy',
		name: 'Sparky',
		pic: '/demo/sparky.webp',
		status: 'FLEXIBLE',
		wannas: [],
		connections: [],
		connectedBy: [
			{
				__typename: 'Friendship',
			},
		],
	},
}

export const me = {
	__typename: 'User',
	id: 'me',
	name: 'Lara Croft',
	pic: '/demo/lara.webp',
	status: null,
	connections: [
		// friends
		users.nick,
		users.jeff,
		users.james,
		users.sparky,
		// circle members
	],
}

// TODO: add more friends for this
circles[0].members = [users.jeff, me]

export const friends = [users.jeff, users.james, users.nick, users.sparky]

export const wannas = [
	{
		id: '1239874kljnsdf',
		activity: 'pizza-palooza',
		created: hoursOldDate(1),
		startTime: dayjs().add(3, 'hour').unix(),
		closed: null,
		shareable: true,
		creator: users.sparky,
		invitations: [
			{
				id: 1,
				user: me,
				created: minutesOldDate(31),
				status: null,
				addedBy: me,
				rsvp: 'YES',
			},
			{
				id: 2,
				user: users.jeff,
				created: minutesOldDate(30),
				status: 'PRESENT',
				addedBy: me,
				rsvp: 'YES',
			},
			{
				id: 3,
				user: users.james,
				created: minutesOldDate(30),
				status: null,
				addedBy: me,
				rsvp: null,
			},
			{
				id: 4,
				user: users.nick,
				created: minutesOldDate(30),
				status: 'TYPING',
				rsvp: null,
			},
			{
				id: 5,
				user: users.sparky,
				status: 'TYPING',
				addedBy: users.jeff,
				created: Date.now(),
				rsvp: 'YES',
			},
		].map((p) => ({ ...p, __typename: 'Invitation' })),
		messages: [
			{
				id: 1,
				content: "Let's hit that Domino's deal",
				sent: minutesOldDate(10),
				sender: users.jeff,
				seenBy: [users.james],
				likes: 5,
			},
			{
				id: 4,
				content: "Can they cut it into 6 slices? I can't finish 8",
				sent: minutesOldDate(3),
				sender: users.james,
				seenBy: [users.jeff],
				likes: 0,
			},
			{
				id: 9,
				content: 'Is catnip a topping? 😺🍕',
				sent: minutesOldDate(1),
				sender: users.sparky,
				seenBy: [users.nick],
				likes: 2,
			},
		]
			.reverse()
			.map((m) => ({ ...m, __typename: 'Message' })),
	},
].map((w) => ({ ...w, __typename: 'Wanna' }))

export const notifications = wannas.map((wanna) => ({
	__typename: 'Notification',
	id: wanna.id,
	title: wanna.invitations[0].user.name,
	pic: wanna.invitations[0].user.pic,
	body: 'Wanna ' + wanna.activity + '?',
	path: '/app/wannas/' + wanna.id,
	timestamp: wanna.created,
	seen: null,
}))

function minutesOldDate(minutes) {
	return new Date(Date.now() - minutes * 60 * 1000)
}

function hoursOldDate(hours) {
	return new Date(Date.now() - hours * 60 * 60 * 1000)
}

function _makeConvo(messages, { timeAdded }) {
	startingMessageId += 100
	return messages.map((m, i) => ({
		__typename: 'Message',
		id: startingMessageId + i,
		content: Object.values(m)[0],
		sent: minutesOldDate(i + timeAdded),
		sender: Object.values(users).find((u) => u.id === Object.keys(m)[0]),
		seenBy: [],
		likes: 0,
	}))
}

import { useQuery } from '@apollo/client'
import Notifications from '@mui/icons-material/Notifications'
import { Badge, IconButton } from '@mui/material'
import { useNavigate } from 'react-router'
import GET_UNREAD_NOTIFICATIONS from '../../gql/getUnreadNotifications.gql'

export const NotificationButton = ({ sx }) => {
	const navigate = useNavigate()
	const { data: { notifications } = {} } = useQuery(GET_UNREAD_NOTIFICATIONS)

	return (
		<IconButton
			sx={sx}
			color='inherit'
			onClick={() => navigate('/app/notifications')}>
			<Badge
				color='secondary'
				badgeContent={notifications?.filter((n) => !n.seen).length}>
				<Notifications />
			</Badge>
		</IconButton>
	)
}

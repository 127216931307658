import {
	Avatar,
	Chip,
	Dialog,
	DialogContentText,
	Button,
	DialogActions,
	useTheme,
} from '@mui/material'
import { forwardRef, useEffect, useState } from 'react'
import { useMe } from '../hooks/useMe'
import { useImDown } from '../hooks/useImDown'
import { UserSelector } from './UserSelector'
import { useMyConnections } from '../hooks/useMyConnections.js'

export const ImDown = forwardRef(({ wannaId }, ref) => {
	const me = useMe()
	const theme = useTheme()
	const [isInviteOpen, setIsInviteOpen] = useState(false)
	const [inviteUserIds, setInviteUserIds] = useState([])
	const myConnections = useMyConnections()
	useEffect(() => {
		setInviteUserIds(myConnections?.map((user) => user.id) ?? [])
	}, [myConnections])

	const { imDown, imNotDown, amIDown, shareable } = useImDown(wannaId)

	return (
		<>
			<Chip
				ref={ref}
				onClick={(e) => {
					e.stopPropagation()
					if (amIDown) imNotDown()
					else if (shareable) setIsInviteOpen(true)
					else imDown()
				}}
				variant={amIDown ? 'contained' : 'outlined'}
				label={"I'm down!"}
				color='secondary'
				sx={{
					// can't use `Chip.color` because it must be from palette -.-
					background: amIDown ? 'secondary' : theme.palette.background.default,
				}}
				avatar={
					<Avatar
						alt={me?.name}
						src={me?.pic}
					/>
				}
			/>
			{isInviteOpen && (
				<Dialog
					open={isInviteOpen}
					onClose={() => setIsInviteOpen(false)}>
					<DialogContentText
						p={2}
						pb={0}>
						this activity allows friends-of-friends!
						<br />
						share it with...
					</DialogContentText>
					<UserSelector
						selectedUserIds={inviteUserIds}
						onSelected={(userIds) =>
							setInviteUserIds((prev) => prev.concat(userIds))
						}
						onDeselected={(userIds) =>
							setInviteUserIds((prev) =>
								prev.filter((id) => !userIds.includes(id))
							)
						}
					/>
					<DialogActions>
						<Button onClick={() => setIsInviteOpen(false)}>cancel</Button>
						<Button
							onClick={() => {
								imDown(inviteUserIds)
								setIsInviteOpen(false)
							}}>
							confirm
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</>
	)
})
ImDown.displayName = 'ImDown'

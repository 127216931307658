import { createMockClient, createMockSubscription } from 'mock-apollo-client'
import { useMemo, useState } from 'react'
import GET_WANNA_CHAT from '../../gql/getWannaChat.gql'
import GET_MY_CONNECTIONS from '../../gql/getMyConnections.gql'
import GET_INVITEES from '../../gql/getInvitees.gql'
import GET_INVITED_WANNAS from '../../gql/getInvitedWannas.gql'
import GET_JOINED_WANNAS from '../../gql/getJoinedWannas.gql'
import GET_MY_CURRENT_WANNA from '../../gql/getMyCurrentWanna.gql'
import GET_WANNA_HEADER from '../../gql/getWannaHeader.gql'
import I_WANNA from '../../gql/iWanna.gql'
import I_DONT_WANNA from '../../gql/iDontWanna.gql'
import GET_PROFILE from '../../gql/getProfile.gql'
import ON_MESSAGES from '../../gql/onMessages.gql'
import UPDATE_MY_STATUS from '../../gql/updateMyStatus.gql'
import IM_DOWN from '../../gql/imDown.gql'
import IM_DOWN_DATA from '../../gql/imDownData.gql'
import IM_NOT_DOWN from '../../gql/imNotDown.gql'
import SEARCH_USERS from '../../gql/searchUsers.gql'
import SEND_MESSAGE from '../../gql/sendMessage.gql'
import ON_INVITATIONS_CHANGED from '../../gql/onInvitationsChanged.gql'
import ON_INVITATION_UPDATED from '../../gql/onInvitationUpdated.gql'
import ON_WANNA_CREATED from '../../gql/onWannaCreated.gql'
import GET_UNREAD_NOTIFICATIONS from '../../gql/getUnreadNotifications.gql'
import UPDATE_STATUS from '../../gql/updateStatus.gql'
import GET_ME from '../../gql/getMe.gql'
import GET_SETTINGS from '../../gql/getSettings.gql'
import GET_MY_CIRCLES from '../../gql/getMyCircles.gql'
import GET_CIRCLE from '../../gql/getCircle.gql'
import LIKE_MESSAGE from '../../gql/likeMessage.gql'
import GET_NOTIFICATIONS from '../../gql/getNotifications.gql'
import MARK_NOTIFICATIONS_AS_READ from '../../gql/markNotificationsAsRead.gql'
import LOGOUT from '../../gql/logout.gql'
import {
	me as initialMe,
	friends,
	circles,
	wannas as initialWannas,
	notifications as initialNotifications,
	users,
} from './demoData'
import { createCache } from '../../lib/apolloCache'

// TODO: fix circular references
export const useDemoClient = () => {
	const [isLoggedIn, setIsLoggedIn] = useState(true)
	const [me, setMe] = useState(initialMe)
	const [wannas, setWannas] = useState(initialWannas)
	const [notifications, setNotifications] = useState(initialNotifications)
	// Cache should persist across clients
	const cache = useMemo(() => createCache(), [])
	// We need to return a new client each time the data changes
	// because it doesn't support resetting request handlers.
	const mockClient = useMemo(() => {
		const client = createMockClient({
			cache,
		})

		client.setRequestHandler(GET_ME, () =>
			Promise.resolve({ data: { me: isLoggedIn ? me : null } })
		)
		client.setRequestHandler(SEARCH_USERS, ({ query }) =>
			Promise.resolve({
				data: {
					searchUsers: Object.values(users).filter((user) =>
						user.name.toLowerCase().includes(query.toLowerCase())
					),
				},
			})
		)
		client.setRequestHandler(GET_PROFILE, ({ userId }) =>
			Promise.resolve({
				data: {
					user: users[userId],
					friends: friends,
				},
			})
		)
		client.setRequestHandler(GET_MY_CIRCLES, () =>
			Promise.resolve({
				data: {
					circles: circles,
				},
			})
		)
		client.setRequestHandler(GET_CIRCLE, ({ id }) =>
			Promise.resolve({
				data: {
					circle: circles.find((circle) => circle.id === id),
				},
			})
		)
		client.setRequestHandler(GET_NOTIFICATIONS, () =>
			Promise.resolve({ data: { notifications } })
		)
		client.setRequestHandler(GET_UNREAD_NOTIFICATIONS, () => {
			return Promise.resolve({
				data: {
					notifications: notifications.filter((n) => !n.seen),
				},
			})
		})
		client.setRequestHandler(MARK_NOTIFICATIONS_AS_READ, ({ ids }) => {
			const updated = notifications.map((n) =>
				ids.includes(n.id) ? { ...n, seen: Date.now() } : n
			)
			setNotifications(updated)
			return Promise.resolve({
				data: { markNotificationsAsRead: updated },
			})
		})
		client.setRequestHandler(GET_MY_CURRENT_WANNA, () =>
			Promise.resolve({
				data: {
					me: {
						id: me.id,
						currentWanna:
							wannas.find((wanna) => wanna.creator.id === me.id) ?? null,
					},
				},
			})
		)
		client.setRequestHandler(GET_INVITED_WANNAS, () =>
			Promise.resolve({
				data: {
					me: {
						id: me.id,
						__typename: 'User',
						invitedWannas: wannas.filter((wanna) =>
							wanna.invitations.map((inv) => inv.user.id).includes(me.id)
						),
					},
				},
			})
		)
		client.setRequestHandler(GET_JOINED_WANNAS, () =>
			Promise.resolve({
				data: {
					me: {
						id: me.id,
						__typename: 'User',
						invitedWannas: wannas.filter((wanna) =>
							wanna.invitations.map((inv) => inv.user.id).includes(me.id)
						),
					},
				},
			})
		)
		client.setRequestHandler(GET_WANNA_HEADER, ({ id }) => {
			const wanna = wannas.find((wanna) => wanna.id === id)
			return Promise.resolve({
				data: {
					wanna,
				},
			})
		})
		client.setRequestHandler(GET_WANNA_CHAT, ({ id }) => {
			const wanna = wannas.find((wanna) => wanna.id === id)
			return Promise.resolve({
				data: {
					wanna: {
						// * Important that we only return the fields it asked for for this and members query
						// Otherwise it gets freaky with the cache or something and spams these queries, using tons of CPU + memory
						id: wanna.id,
						__typename: 'Wanna',
						messages: wanna.messages,
					},
				},
			})
		})
		client.setRequestHandler(GET_INVITEES, ({ wannaId }) => {
			const wanna = wannas.find((wanna) => wanna.id === wannaId)
			return Promise.resolve({
				data: {
					wanna,
				},
			})
		})
		client.setRequestHandler(GET_MY_CONNECTIONS, () =>
			Promise.resolve({
				data: {
					me: me,
				},
			})
		)
		client.setRequestHandler(
			I_WANNA,
			({ activity, startTime, shareable, inviteUserIds }) => {
				const newWannaId =
					Math.random().toString(36).substring(2, 15) +
					Math.random().toString(36).substring(2, 15)
				const invitationIdToStartAt = Math.max(
					...wannas.flatMap((w) => w.invitations.map((m) => m.id))
				) + 1
				const newWanna = {
					id: newWannaId,
					__typename: 'Wanna',
					activity,
					created: Date.now(),
					creator: me,
					invitations: [
						{
							id: invitationIdToStartAt,
							__typename: 'Invitation',
							user: me,
							status: null,
							rsvp: 'YES',
						},
						...Object.values(users)
							.filter((user) => inviteUserIds.includes(user.id))
							.map((user, i) => ({
								id: invitationIdToStartAt + i + 1,
								__typename: 'Invitation',
								user,
								status: null,
								rsvp: null,
							})),
					],
					startTime,
					closed: null,
					shareable,
					messages: [],
				}
                console.log("newWanna", newWanna)
				setWannas((prev) => [newWanna, ...prev])
				return Promise.resolve({
					data: {
						iWanna: newWanna,
					},
				})
			}
		)
		client.setRequestHandler(I_DONT_WANNA, () => {
			const updatedWannas = wannas.map((wanna) =>
				wanna.creator.id === me.id ? { ...wanna, closed: Date.now() } : wanna
			)
			setWannas(updatedWannas)
			return Promise.resolve({
				data: {
					iDontWanna: updatedWannas.find((wanna) => wanna.creator.id === me.id),
				},
			})
		})
		client.setRequestHandler(UPDATE_MY_STATUS, ({ status }) => {
			setMe((prev) => ({ ...prev, status }))
			return Promise.resolve({
				data: {
					updateMyStatus: {
						__typename: 'User',
						id: me.id,
						status,
					},
				},
			})
		})
		client.setRequestHandler(IM_DOWN_DATA, ({ wannaId }) => {
			const wanna = wannas.find((wanna) => wanna.id === wannaId)
			return Promise.resolve({
				data: {
					wanna,
				},
			})
		})
		client.setRequestHandler(IM_DOWN, ({ wannaId }) => {
			setWannas((prev) =>
				prev.map((wanna) =>
					wanna.id === wannaId
						? {
								...wanna,
								invitations: wanna.invitations.map((inv) =>
									inv.user.id === me.id
										? {
												...inv,
												rsvp: 'YES',
											}
										: inv
								),
							}
						: wanna
				)
			)
			return Promise.resolve({
				data: {
					imDown: {
						id: wannas
							.find((wanna) => wanna.id === wannaId)
							.invitations.find((inv) => inv.user.id === me.id).id,
						__typename: 'Invitation',
						rsvp: 'YES',
					},
				},
			})
		})
		client.setRequestHandler(IM_NOT_DOWN, ({ wannaId }) => {
			setWannas((prev) =>
				prev.map((wanna) =>
					wanna.id === wannaId
						? {
								...wanna,
								invitations: wanna.invitations.map((inv) =>
									inv.user.id === me.id
										? {
												...inv,
												rsvp: null,
											}
										: inv
								),
							}
						: wanna
				)
			)
			return Promise.resolve({
				data: {
					imNotDown: {
						id: wannas
							.find((wanna) => wanna.id === wannaId)
							.invitations.find((inv) => inv.user.id === me.id).id,
						__typename: 'Invitation',
						rsvp: null,
					},
				},
			})
		})
		client.setRequestHandler(SEND_MESSAGE, ({ wannaId, content }) => {
			const targetWanna = wannas.find((wanna) => wanna.id === wannaId)
			const newMsg = {
				id: Math.max(...wannas.flatMap((w) => w.messages.map((m) => m.id))) + 1,
				__typename: 'Message',
				sent: Date.now(),
				content: content,
				sender: me,
				likes: 0,
				seenBy: targetWanna.invitations
					.filter((m) => m.user.id !== me.id)
					.filter((m) => m.status != null)
					.map((m) => m.user),
			}
			const newWanna = {
				...targetWanna,
				messages: [newMsg, ...targetWanna.messages],
			}
			setWannas((prev) =>
				prev.filter((wanna) => wanna.id !== wannaId).concat([newWanna])
			)
			return Promise.resolve({
				data: {
					sendMessage: newMsg,
				},
			})
		})
		client.setRequestHandler(LIKE_MESSAGE, ({ messageId }) => {
			const targetWanna = wannas.find((wanna) =>
				wanna.messages.some((m) => m.id === messageId)
			)
			const targetMessage = targetWanna.messages.find((m) => m.id === messageId)
			const newMessage = { ...targetMessage, likes: targetMessage.likes + 1 }
			const newWanna = {
				...targetWanna,
				messages: targetWanna.messages.map((m) =>
					m.id === messageId ? newMessage : m
				),
			}
			setWannas((prev) =>
				prev.filter((wanna) => wanna.id !== targetWanna.id).concat([newWanna])
			)
			return Promise.resolve({
				data: {
					likeMessage: newMessage,
				},
			})
		})
		client.setRequestHandler(UPDATE_STATUS, ({ status }) =>
			Promise.resolve({
				data: {
					updateStatus: {
						id: me.id,
						__typename: 'Invitation',
						status,
					},
				},
			})
		)
		client.setRequestHandler(GET_SETTINGS, () =>
			Promise.resolve({
				data: {
					settings: {
						__typename: 'Settings',
						pushSubscriptionEndpoints: [],
					},
				},
			})
		)

		client.setRequestHandler(LOGOUT, () => {
			setIsLoggedIn(false)
			return Promise.resolve({ data: { logout: me.id } })
		})

		client.setRequestHandler(ON_MESSAGES, () => createMockSubscription())
		client.setRequestHandler(ON_INVITATIONS_CHANGED, () =>
			createMockSubscription()
		)
		client.setRequestHandler(ON_INVITATION_UPDATED, () =>
			createMockSubscription()
		)
		client.setRequestHandler(ON_WANNA_CREATED, () => createMockSubscription())

		return client
	}, [wannas, friends, circles, me, notifications, isLoggedIn])

	return mockClient
}

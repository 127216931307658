import { useQuery } from '@apollo/client'
import AddIcon from '@mui/icons-material/Add'
import WorkspacesIcon from '@mui/icons-material/Workspaces'
import {
	AppBar,
	Button,
	Dialog,
	Fab,
	List,
	Paper,
	Stack,
	Toolbar,
} from '@mui/material'
import { ToolbarTitle } from '../../components/ToolbarTitle'
import GET_MY_CIRCLES from '../../gql/getMyCircles.gql'
import { CircleItem } from './CircleItem'
import { Blurb } from '../../components/Blurb'
import { useState } from 'react'
import { useCreateCircle } from '../../hooks/useCreateCircle'

export const Circles = () => {
	const [name, setName] = useState('')
	const createCircle = useCreateCircle()
	const [createOpen, setCreateOpen] = useState(false)
	const { data: { circles } = {} } = useQuery(GET_MY_CIRCLES)

	return (
		<Stack
			height='100%'
			minHeight={0}>
			<AppBar position='static'>
				<Toolbar>
					<ToolbarTitle>circles</ToolbarTitle>
				</Toolbar>
			</AppBar>
			{circles?.length === 0 && (
				<Blurb
					icon={<WorkspacesIcon sx={{ width: '64px', height: '64px' }} />}
					text='circles help you connect with people from shared communities, like a dorm, team, or workplace. try creating and sharing one!'
				/>
			)}
			<List
				height='100%'
				sx={{ overflow: 'auto' }}>
				{circles?.map((circle) => (
					<CircleItem
						key={circle.id}
						circle={circle}
					/>
				))}
			</List>
			<Fab
				sx={{
					right: 20,
					bottom: 76,
					position: 'absolute',
				}}
				onClick={() => setCreateOpen(true)}
				color='secondary'>
				<AddIcon />
			</Fab>
			<Dialog
				sx={{
					'& .MuiDialog-paper': {
						backgroundColor: 'primary.main',
						color: 'white',
						borderRadius: '16px',
					},
				}}
				open={createOpen}
				onClose={() => {
					setName('')
					setCreateOpen(false)
				}}>
				<Stack
					p={2}
					gap={1}>
					<Paper
						elevation={2}
						style={{
							background: 'white',
							borderRadius: '8px',
							outline: 'none',
							width: '100%',
						}}>
						<input
							style={{
								width: '100%',
								background: 'white',
								borderRadius: '8px',
								fontSize: '20px',
								padding: '8px',
								textAlign: 'center',
								border: 'none',
								fontFamily: 'inherit',
								outline: 'none',
							}}
							placeholder='circle name'
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
					</Paper>
					<Button
						variant='contained'
						color='secondary'
						sx={{ borderRadius: '8px', fontSize: '16px' }}
						disabled={name.length < 3 && name.length <= 32}
						onClick={() => {
							createCircle(name)
							setName('')
							setCreateOpen(false)
						}}>
						create
					</Button>
				</Stack>
			</Dialog>
		</Stack>
	)
}

import { useQuery } from '@apollo/client'
import GET_JOINED_WANNAS from '../gql/getJoinedWannas.gql'
import { useMessagesSubscription } from './useMessagesSubscription'

export const useJoinedWannas = () => {
	const { data } = useQuery(GET_JOINED_WANNAS, {})
	const me = data?.me
	const joinedWannas =
		me?.invitedWannas.filter((wanna) =>
			wanna.invitations.some(
				(inv) => inv.user.id === me?.id && inv.rsvp === 'YES'
			)
		) ?? []

	useMessagesSubscription(joinedWannas.map((wanna) => wanna.id))

	return joinedWannas
}

import { useQuery } from '@apollo/client'
import { Alert, Button, Snackbar, Stack } from '@mui/material'
import { useState } from 'react'
import { useCookies } from 'react-cookie'
import GET_NOTIFY_WORTHY_FIELDS from '../../gql/getNotifyWorthyFields.gql'
import { useNotificationAutoClear } from '../../hooks/useNotificationAutoClear.js'
import { usePushNotifications } from '../../hooks/usePushNotifications.js'
import { InstallInstructions } from './InstallInstructions.jsx'
import { useIsDemo } from '../../hooks/useIsDemo.js'

export const AutoPushRequester = () => {
	// TODO call this elsewhere, in own exclusive component or something.
	// Shouldn't be the responsbility of this component.
	useNotificationAutoClear()

	const [isPushEnabled, setPushEnabled] = usePushNotifications()
	const [cookies, setCookies] = useCookies(['disableAutoPushRequest'])
	const isDemo = useIsDemo()
	const [showInstructions, setShowInstructions] = useState(false)
	const [hasShownInstructions, setHasShownInstructions] = useState(false)
	const { data = {} } = useQuery(GET_NOTIFY_WORTHY_FIELDS, {
		skip: isPushEnabled === null || isPushEnabled === true,
		fetchPolicy: 'cache-only',
		returnPartialData: true,
	})

	if (cookies.disableAutoPushRequest || isDemo) return null

	let message
	if (data && isPushEnabled === false) {
		if (data?.me?.invitedWannas?.length > 0) {
			message = `yay, activities! receive notifications for messages?`
		} else if (data?.connections?.length > 0) {
			message = `yay, you have connections! receive notifications when they wanna do something?`
		}
	}

	const handleYes = () => {
		if (setPushEnabled) {
			setPushEnabled(true)
		} else {
			setShowInstructions(true)
		}
	}
	const handleNo = () => setCookies('disableAutoPushRequest', true, { path: '/', expires: false })

	const action = (
		<Stack
			height='100%'
			justifyContent='space-around'
			direction='row'
			flexWrap='wrap'
			gap={1.5}>
			<Button
				sx={{ px: 3, py: 1 }}
				variant='contained'
				onClick={handleYes}>
				sure!
			</Button>
			<Button
				sx={{ px: 3, py: 1 }}
				variant='outlined'
				onClick={handleNo}
				color='error'>
				nah
			</Button>
		</Stack>
	)

	return (
		<>
			<Snackbar
				open={message != null && !hasShownInstructions}
				sx={{ position: 'absolute' }}>
				<Alert
					action={action}
					severity='info'
					sx={{ width: '100%' }}>
					{message}
				</Alert>
			</Snackbar>
			<InstallInstructions
				isOpen={showInstructions && !hasShownInstructions}
				onClose={() => setHasShownInstructions(true)}
			/>
		</>
	)
}

import { useMe } from '../hooks/useMe'
import { InviteeChip } from './InviteeChip'
import FlipMove from 'react-flip-move'
import { ImDown } from './ImDown'

export const InlineInvitees = ({ wannaId, invitations }) => {
	const me = useMe()

	const invs = invitations
		.filter((inv) => inv.user.id !== me?.id)
		.sort((a, b) => compare(a, b, me?.id))

	return (
		<FlipMove
			style={{
				overflow: 'visible',
				flex: 1,
				display: 'flex',
				flexDirection: 'row',
				flexWrap: 'wrap',
				alignItems: 'end',
				gap: '4px',
			}}>
			{/* TODO: ImDown goes at the end when logging in on this page? and it doesn't remove your own chip either if you're already down (rare edge case tho) */}
			{me && (
				<ImDown
					key='imDown'
					wannaId={wannaId}
				/>
			)}
			{invs.map((inv) => (
				<InviteeChip
					key={inv.id}
					wannaId={wannaId}
					invitation={inv}
				/>
			))}
		</FlipMove>
	)
}

function compare(invA, invB, meId) {
	return (
		(invB.id === meId) - (invA.id === meId) || // Me first
		(invB.rsvp === 'YES') - (invA.rsvp === 'yes') ||
		(invB.status === 'TYPING') - (invA.status === 'TYPING') ||
		(invB.status === 'PRESENT') - (invA.status === 'PRESENT') ||
		invB.user.name.localeCompare(invA.user.name)
	)
}

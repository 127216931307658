import GroupIcon from '@mui/icons-material/Group'
import {
	Button,
	Box,
	Checkbox,
	Dialog,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormGroup,
	Stack,
	Typography,
} from '@mui/material'
import EventIcon from '@mui/icons-material/Event'
import { MobileDateTimePicker } from '@mui/x-date-pickers'
import { useState, useEffect } from 'react'
import { useIWanna } from '../../hooks/useIWanna.js'
import { useIDontWanna } from '../../hooks/useIDontWanna.js'
import { useMyCurrentWanna } from '../../hooks/useMyCurrentWanna.js'
import dayjs from 'dayjs'
import { UserSelector } from '../../components/UserSelector.jsx'
import { useMyConnections } from '../../hooks/useMyConnections.js'
import { HorizontalLogo } from '../../components/HorizontalLogo.jsx'
import { LargeSwitch } from '../../components/LargeSwitch.jsx'
import { InvitedWannas } from './InvitedWannas.jsx'
import { ActivityInput } from './ActivityInput.jsx'
import { NotificationButton } from '../notifications/NotificationButton.jsx'

export const IWanna = ({ context }) => {
	// Settings
	const [activity, setActivity] = useState('')
	const [unchangedDate] = useState(dayjs())
	const [date, setDate] = useState(unchangedDate)
	const [isInviteOpen, setIsInviteOpen] = useState(false)
	const [inviteUserIds, setInviteUserIds] = useState([])
	const [shareable, setShareable] = useState(true)

	const myConnections = useMyConnections()
	function myContextualConnections() {
		if (!myConnections) return []
		return context
			? myConnections.filter((user) =>
					user.connectedBy.some((connection) => connection.id === context)
				)
			: myConnections
	}
	useEffect(() => {
		setInviteUserIds(myContextualConnections().map((user) => user.id))
	}, [myConnections])

	const iWanna = useIWanna()
	const iDontWanna = useIDontWanna()
	const myCurrentWanna = useMyCurrentWanna()

	useEffect(() => {
		setActivity(myCurrentWanna?.activity ?? '')
		setInviteUserIds(
			(
				myCurrentWanna?.invitations.map((inv) => inv.user) ??
				myContextualConnections()
			).map((user) => user.id)
		)
		setDate(
			myCurrentWanna ? dayjs.unix(myCurrentWanna.startTime) : unchangedDate
		)
		setShareable(myCurrentWanna?.shareable ?? true)
	}, [myCurrentWanna?.id])

	return (
		<Stack // Holds creator and wannas list
			height='100%'
			justifyContent='center'>
			<NotificationButton
				sx={{
					position: 'absolute',
					top: 12,
					right: 12,
					color: 'white',
				}}
			/>
			<Stack // Creator container; just centers it
				flex={1}
				backgroundColor='primary.main'
				alignItems='center'
				justifyContent='center'
				boxShadow={2}>
				<Stack // The real creator content
					gap={1}
					px={4}
					py={2}
					maxWidth='400px'
					minHeight='fit-content'
					color='white'
					justifyContent='center'
					alignItems='center'>
					<HorizontalLogo style={{ width: '79%', maxWidth: '300px' }} />
					<ActivityInput
						activity={activity}
						setActivity={setActivity}
						disabled={!!myCurrentWanna}
					/>
					<Stack // Options (hidden when switched on)
						px={2}
						alignSelf='stretch'
						sx={{
							transition: 'max-height 1s, opacity 1s',
							transitionDelay: myCurrentWanna ? '0s' : '1s',
							opacity: myCurrentWanna ? 0 : 1,
						}}
						overflow='hidden'
						maxHeight={myCurrentWanna ? 0 : 500}>
						<ButtonField
							startIcon={<GroupIcon />}
							onClick={() => setIsInviteOpen(true)}>
							<Stack
								width='100%'
								alignItems='start'
								textAlign='start'>
								<Typography>
									{`share with ${inviteUserIds.length} ${inviteUserIds.length === 1 ? 'person' : 'people'}`}
								</Typography>
								<Typography variant='caption'>
									or share a link after creation
								</Typography>
							</Stack>
						</ButtonField>
						<MobileDateTimePicker
							slots={{ field: ButtonField }}
							slotProps={{
								value: date,
								field: {
									startIcon: <EventIcon />,
									children: (
										<Stack
											width='100%'
											alignItems='start'
											textAlign='start'>
											<Typography>
												{date === unchangedDate
													? 'now-ish'
													: (date.isToday()
															? 'today'
															: date.format('ddd, MMM D')) +
														' at ' +
														date.format('h:mm a')}
											</Typography>
											<Typography variant='caption'>
												joinable until three hours after
											</Typography>
										</Stack>
									),
								},
							}}
							minDate={unchangedDate}
							minTime={unchangedDate}
							maxDate={dayjs().add(1, 'month')}
							disableIgnoringDatePartForTimeValidation={true}
							value={date}
							onChange={(newDate) => setDate(newDate)}
						/>
						<FormControl sx={{ alignSelf: 'flex-start', px: 1, pb: 2, pt: 1 }}>
							<FormGroup>
								<FormControlLabel
									label={
										<Typography variant='caption'>
											when an invitee joins,
											<br />
											let them invite others
										</Typography>
									}
									control={
										<Checkbox
											sx={{ color: 'white' }}
											color='secondary'
											checked={shareable}
											onClick={() => setShareable((prev) => !prev)}
										/>
									}
								/>
							</FormGroup>
						</FormControl>
					</Stack>
					<LargeSwitch
						checked={!!myCurrentWanna}
						color='secondary'
						disabled={!myCurrentWanna && (!activity || activity.length > 32)}
						onChange={(e) => {
							if (e.target.checked) {
								iWanna(activity, shareable, date.unix(), inviteUserIds)
							} else {
								iDontWanna()
							}
						}}
					/>
				</Stack>
			</Stack>
			<Box
				flex={myCurrentWanna ? 3 : 0}
				sx={{
					transition: 'flex 1s',
					transitionDelay: myCurrentWanna ? '1s' : '0s',
				}}
				overflow='auto'>
				<InvitedWannas />
			</Box>
			<Dialog
				open={isInviteOpen}
				onClose={() => setIsInviteOpen(false)}>
				<DialogTitle>share with...</DialogTitle>
				<UserSelector
					initialChip={context}
					selectedUserIds={inviteUserIds}
					onSelected={(userIds) =>
						setInviteUserIds((prev) => prev.concat(userIds))
					}
					onDeselected={(userIds) =>
						setInviteUserIds((prev) =>
							prev.filter((id) => !userIds.includes(id))
						)
					}
				/>
			</Dialog>
		</Stack>
	)
}

const ButtonField = (props) => {
	return (
		<Button
			sx={{ alignSelf: 'stretch', borderRadius: '8px' }}
			size='large'
			onClick={props.onClick}
			color='white'
			startIcon={props.startIcon}>
			{props.children}
		</Button>
	)
}

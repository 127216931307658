import { useMutation } from '@apollo/client'
import I_DONT_WANNA from '../gql/iDontWanna.gql'
import { useMe } from './useMe'

export const useIDontWanna = () => {
	const me = useMe()
	const [mutation] = useMutation(I_DONT_WANNA, {
		update: (cache, { data: { iDontWanna } }) => {
			cache.modify({
				id: cache.identify(me),
				fields: {
					currentWanna: () => null,
				},
			})
			// Don't evict this till after hide animation finishes
			// setTimeout(() => {
			// 	cache.modify({
			// 		id: cache.identify(me),
			// 		fields: {
			// 			// Otherwise old ones will be visible from the cache when we make a new one.
			// 			invitedWannas: async (existingWannas = []) => {
			// 				const newWannaId = cache.identify(iDontWanna)
			// 				return existingWannas.filter((w) => w.__ref !== newWannaId)
			// 			},
			// 		},
			// 	})
			// }, 1000)
		},
	})
	return () => mutation()
}

import Edit from '@mui/icons-material/Edit'
import { AppBar, FormControl, Stack, Toolbar } from '@mui/material'
import { useState } from 'react'
import { ToolbarTitle } from '../../components/ToolbarTitle'
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	IconButton,
} from '@mui/material'
import { TogglePushNotifications } from './TogglePushNotifications'
import { ToggleDarkMode } from './ToggleDarkMode'
import { useLogout } from '../../hooks/useLogout'
import Logout from '@mui/icons-material/Logout'
import { useMe } from '../../hooks/useMe'
import * as Sentry from '@sentry/react'

export const Settings = () => {
	const me = useMe()
	const [isEditOpen, setEditOpen] = useState(false)
	const logout = useLogout()

	return (
		<Stack height='100%'>
			<AppBar position='static'>
				<Toolbar>
					{/* <Avatar
						alt={me?.name}
						src={me?.pic}
						sx={{ mr: 1.5 }}
					/> */}
					<ToolbarTitle>{me?.name}</ToolbarTitle>
					<IconButton onClick={() => setEditOpen(true)}>
						<Edit sx={{ color: 'white' }} />
					</IconButton>
					<IconButton onClick={() => logout()}>
						<Logout sx={{ color: 'white' }} />
					</IconButton>
				</Toolbar>
			</AppBar>
			<FormControl sx={{ mx: 2, mt: 1 }}>
				<TogglePushNotifications />
				<ToggleDarkMode />
				<Button
					sx={{ mt: 1 }}
					variant='outlined'
					onClick={() =>
						Sentry.feedbackAsyncIntegration()
							.createForm({
								colorScheme: 'system',
								formTitle: 'Give Feedback',
								messagePlaceholder:
									'What suggestion do you have?\nOr what issue did you experience?',
								successMessageText: 'Thank you for your feedback!',
								submitButtonLabel: 'Submit',
							})
							.then((form) => {
								form.appendToDom()
								form.open()
							})
					}>
					give feedback
				</Button>
			</FormControl>
			<Dialog
				open={isEditOpen}
				onClose={() => setEditOpen(false)}>
				<DialogContent>
					<DialogContentText>
						Wanna uses your Google name and profile picture. To update them in
						Wanna, update them on Google below and then log out and back in to
						Wanna.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() =>
							window.open('https://myaccount.google.com/profile', '_blank')
						}>
						my Google profile
					</Button>
					<Button onClick={() => setEditOpen(false)}>close</Button>
				</DialogActions>
			</Dialog>
		</Stack>
	)
}

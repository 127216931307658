import { Avatar, Chip, Box, Collapse, Stack } from '@mui/material'
import { getColor } from '../utils/utils'
import { forwardRef } from 'react'
import { useTheme } from '@emotion/react'

export const InviteeChip = forwardRef(({ wannaId, invitation }, _ref) => {
	const theme = useTheme()
	const color = getColor(wannaId, invitation.user.id)
	const joined = invitation.rsvp === 'YES'
	const present = invitation.status === 'PRESENT' || invitation.status === 'TYPING'
	const user = invitation.user
	return (
		<Box
			color={color}
			position='relative'>
			<Chip
				// TODO: ehh idk if this is clear rn
				size={present ? 'medium' : 'small'}
				// TODO: affects friends and circles?
				variant={joined ? 'filled' : 'outlined'}
				avatar={
					<Avatar
						alt={user.name}
						src={user.pic}
					/>
				}
				sx={{
					// can't use `Chip.color` because it must be from palette -.-
					background: joined ? color : theme.palette.background.default,
					color: joined ? 'white' : color,
					borderColor: joined ? undefined : color,
				}}
				label={user.name.split(' ')[0]}
			/>
			<TypingIndicator isOpen={invitation.status === 'TYPING'} />
		</Box>
	)
})
InviteeChip.displayName = 'InviteeChip'

const TypingIndicator = ({ isOpen }) => {
	return (
		<Collapse
			in={isOpen}
			sx={{ position: 'absolute', zIndex: 10, left: 30, top: -11 }}
			orientation='horizontal'>
			<Stack
				direction='row'
				className='typing-indicator'>
				<span />
				<span />
				<span />
			</Stack>
		</Collapse>
	)
}

// For FlipMove
InviteeChip.prototype = {}

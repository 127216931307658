import { useQuery } from '@apollo/client'
import { useEffect } from 'react'
import GET_INVITEES from '../gql/getInvitees.gql'
import ON_INVITATION_UPDATED from '../gql/onInvitationUpdated.gql'
import ON_INVITATIONS_CHANGED from '../gql/onInvitationsChanged.gql'

export const useInvitees = (wannaId) => {
	const query = useQuery(GET_INVITEES, {
		variables: {
			wannaId: wannaId,
		},
	})

	useEffect(() => {
		const unsub1 = query.subscribeToMore({
			document: ON_INVITATION_UPDATED,
			variables: { wannaId: wannaId },
			// No need for updateQuery here, because the cache is updated automatically
		})

		const unsub2 = query.subscribeToMore({
			document: ON_INVITATIONS_CHANGED,
			variables: { wannaIds: [wannaId] },
		})

		return () => {
			unsub1()
			unsub2()
		}
	}, [wannaId])

	return query.data?.wanna.invitations ?? []
}
